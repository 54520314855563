<template>
  <div class="content-wrapper overflow-visible">
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

    <div class="content">
    <validation-observer ref="VFormCPPT">
      <b-form @submit.prevent="doSubmit" class="card">
      <div class="card">
        <div class="card-header">
          <h6 class="card-title font-weight-semibold">Tambah CPPT SOAP</h6>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label class="" for="">Tanggal</label>
                <div class="input-group">
                  <datepicker v-model="row.arancpp_tanggal" input-class="form-control transparent"
                    placeholder="Pilih Tanggal" class="my-datepicker"
                    calendar-class="my-datepicker_calendar">
                  </datepicker>
                  <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                        class="icon-calendar"></i></span>
                  </div>
                </div>
                <VValidate name="Tanggal" v-model="row.arancpp_tanggal"
                  :rules="toValidate(mrValidation.arancpp_tanggal)" />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label class="" for="">Jam</label>
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                  <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.arancpp_jam">
                  </vue-timepicker>
                </div>
                <VValidate name="Jam" v-model="row.arancpp_jam"
                  :rules="toValidate(mrValidation.arancpp_jam)" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group"><label for="Subjektif">Subjective <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="row.arancpp_subjektif" name="Subjektif" id="Subjektif" rows="3" class="form-control"></b-form-textarea>
              <VValidate name="Subjektif" v-model="row.arancpp_subjektif"
                :rules="toValidate(mrValidation.arancpp_subjektif)" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group"><label for="Objektif">Objective <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="row.arancpp_objektif" name="Objektif" id="Objektif" rows="3" class="form-control"></b-form-textarea>
              <VValidate name="Objektif" v-model="row.arancpp_objektif"
                :rules="toValidate(mrValidation.arancpp_objektif)" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group"><label for="assesmen">Assessment <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="row.arancpp_assesment" name="Assesmen" id="Assesmen" rows="3" class="form-control"></b-form-textarea>
              <VValidate name="Assesmen" v-model="row.arancpp_assesment"
                :rules="toValidate(mrValidation.arancpp_assesment)" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group"><label for="Perencanaan">Planning <strong class="text-danger">*</strong></label>
              <b-form-textarea v-model="row.arancpp_planning" name="Perencanaan" id="Perencanaan" rows="3" class="form-control"></b-form-textarea>
              <VValidate name="Perencanaan" v-model="row.arancpp_planning"
                :rules="toValidate(mrValidation.arancpp_planning)" />

              </div>
            </div>
            <!--
            <div class="col-12">
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Resep Alkes</h6>
                </div>
                <table class="table table-striped table-sm table-hover table-bordered">
                  <thead>
                    <tr>
                      <th width="48">Aksi</th>
                      <th width="320">Nama</th>
                      <th width="150">Jumlah</th>
                      <th width="210">Frekuensi</th>
                      <th>Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(v,k) in resepAlkes" :key="'resepAlkes'+k">
                          <td>
                            <a href="javascript:;" @click="removeAlkes(v,k)"
                              class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                              class="icon-trash"></i></a>
                          </td>

                          <td>
                              <span class="d-flex">
                                  <v-select style="flex: 1;" @input="selectAlkes($event,k)" class="med-selection"
                                  placeholder="Pilih Nama" v-model="resepAlkes[k].arancpak_item_id"
                                  :options="mAlatKesehatan" label="text" :clearable="true" :reduce="v=>v.value">
                                  </v-select>
                                  <a href="javascript:;" @click="resepAlkes[k]['arancpak_item_id'] = 99999"
                                  v-b-tooltip.hover="'Pilih Lainnya'"
                                  class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                      class="icon-plus-circle2"></i></a>
                              </span>

                             <div v-if="resepAlkes[k]['arancpak_item_id'] == 99999">
                              <b-form-input type="text"
                                v-model="resepAlkes[k]['arancpak_item_id_lainnya']" class="form-control"
                                id="itemCount" />
                                <VValidate :name="'Nama Lainnya '+(k+1)"
                                message="Nama Lainnya Harus Diisi"
                                v-if="resepAlkes[k].arancpak_jenis != 2"
                                v-model="resepAlkes[k]['arancpak_item_id_lainnya']"
                                :rules="{required : 1}" />
                            </div>
                          </td>
                          

                          <td>                          
                            <div class="input-group">
                                <b-form-input type="text" v-model="resepAlkes[k]['arancpak_jumlah']"
                                :formatter="number" class="form-control" id="itemCount" />
                                <div class="input-group-append">
                                <span class="input-group-text">{{resepAlkes[k]['arancpak_satuan']}}</span>
                                </div>
                            </div>

                            <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                                v-model="resepAlkes[k]['arancpak_jumlah']" :rules="{required : 1}" />

                          <td>
                          <span class="d-flex">
                              <v-select style="flex: 1;" class="med-selection"
                              placeholder="Pilih Frekuensi" v-model="resepAlkes[k].arancpak_frekuensi"
                              :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                              </v-select>
                              <a href="javascript:;" @click="resepAlkes[k]['arancpak_frekuensi'] = 99999"
                              v-b-tooltip.hover="'Pilih Lainnya'"
                              class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                                  class="icon-plus-circle2"></i></a>
                          </span>
                          <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                              v-model="resepAlkes[k]['arancpak_frekuensi']" 
                              v-if="resepAlkes[k].arancpak_jenis != 2"
                              :rules="{required : 1}" />

                          <div v-if="resepAlkes[k]['arancpak_frekuensi'] == 99999">
                              <b-form-input type="text"
                              v-model="resepAlkes[k]['arancpak_frekuensi_lainnya']" class="form-control"
                              id="itemCount" />
                              <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                              message="Frekuensi Lainnya Harus Diisi"
                              v-if="resepAlkes[k].arancpak_jenis != 2"
                              v-model="resepAlkes[k]['arancpak_frekuensi_lainnya']"
                              :rules="{required : 1}" />
                          </div>
                          </td>

                          <td>
                          <b-textarea v-model="resepAlkes[k].arancpak_keterangan" name="itemNotes"
                              id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                              class="form-control"></b-textarea>
                          </td>
                      </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td class="text-center" colspan="99">
                        <a href="javascript:;" @click="openAlkes()" class="btn alpha-blue" data-toggle="modal" data-target="#modalResep">
                          <i class="icon-plus2 mr-1 align-middle"></i>
                          <span class="align-middle">Tambah Resep</span>
                        </a>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-8">
                      <div>
                        <label for="">Catatan</label>
                        <textarea v-model="row.arancpp_keterangan" class="form-control" rows="3"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            -->
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
          </div>
        </div>
      </div>
      </b-form>
    </validation-observer>
      <!-- /content area -->
      <!-- Footer -->
      <!-- /footer -->
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
  extends: GlobalVue,
  components:{ Datepicker,VueTimepicker,PatientInfo },
  data(){
    return {
      rowReg: {},
      resepAlkes: [],
      mAlatKesehatan: [],
      mDosis: [],

      patientData: {},
      loading: {
          patientInfo: false,
      }
    }
  },
  methods: {
    back() {
      this.$router.push({name: 'RanapCPPT', params: {pageSlug: this.pageSlug}}).catch(() => {})
    },
    apiGet(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.$route.params.cpptNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.cpptNo : this.modulePage+'/'+this.$route.params.pageSlug
      
      Gen.apiRest(
          "/get/"+url, 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          this.pageNow = page
          this.last_page = this.data.last_page
          
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.cpptNo){
          Gen.apiRest(
              "/get/"+url+'?master=1', 
              {
              params: Object.assign({page: page}, paramsQuery, params.query||{})
              }
          ).then(res=>{
              console.log(res.data)
              _.forEach(res.data, (v,k)=>{
                  this.$set(this, k, v)
              })
          })
      }    
    },

    getPatientInfo() {
        this.$set(this.loading, 'patientInfo', true)
        Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
            data: {
              type: "get-data-pasien",
              id_registrasi: this.$route.params.pageSlug
            }
        },
        "POST"
        ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
        })
    },

    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'

      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    doSubmit(){
      if(this.row.arancp_is_active == 'N'){
        return this.$swal({
            icon: 'error',
            title: 'Data Telah tidak Aktif',
            text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
        }).then(result => {
            if (result.value) {
              setTimeout(()=>{
                  let inv = []
                  let el = document.querySelectorAll('.label_error')
                  for(let i = 0; i < (el||[]).length; i++){
                      if(el[i].style.display !== 'none'){
                          inv.push(el[i].id)
                      }
                  }
                  if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
              },500)
            }
        })
      }

      this.$refs['VFormCPPT'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      console.log(el)
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if(this.row.arancpp_is_resep=='Y' && !(this.resepAlkes||[]).length){
          return this.$swal({
              icon: 'error',
              title: 'Mohon Lengkapi Resep Obat Terlebih Dahulu'
          })
        }

        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.row
              data.type = 'submit-data'
              data.resepAlkes = this.resepAlkes

              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: 'RanapCPPT', params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  })
              })
            }
          })
        }
      })
    },
    
    toValidate(val) {
      return {
        ...val
      }
    },

    openAlkes(v = null) {
        let data = {
            arancpak_aranr_id: this.row.arancp_aranr_id,
            arancpak_arancp_id: this.row.arancp_id,
            arancpak_item_id: null,
            arancpak_nama: null,
            arancpak_jumlah: null,
            arancpak_satuan: null,
            arancpak_frekuensi: null,
            arancpak_frekuensi_lainnya: null,
            arancpak_keterangan: null,
            id: null
        }
        if (!this.row.isEdit) {
            data.type = 'add-resep-alkes'
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                this.apiGet()
            })
        } else {
            this.resepAlkes.push(data)
        }

    },
    
    removeAlkes(v, k) {
        if (!this.row.isEdit) {
            let data = {
                type: 'remove-resep-alkes',
                id: v.arancpak_id
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                this.resepAlkes.splice(k, 1)
            })
        } else {
            this.resepAlkes.splice(k, 1)
        }
    },


    selectAlkes(e, k) {
        let index = this.mAlatKesehatan.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.mAlatKesehatan[index]
            console.log(data)
            this.resepAlkes[k]['arancpak_satuan'] = data.satuan
            this.resepAlkes[k]['arancpak_nama'] = data.text
        }
    },

    autoSaveResepAlkes: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-alkes',
        data: data
      }
      if (!this.row.isEdit) {
        Gen.apiRest(
            "/do/" + this.modulePage, {
            data: dataPost
            },
            "POST"
        )
      }
    }, 1000),
            
  },
  mounted() {
    this.apiGet()

    this.getPatientInfo()
  },
  
  watch: {
    row: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
    resepAlkes: {
      handler(v) {
          this.autoSaveResepAlkes(v)
      },
      deep: true
    },
  }
}

</script>